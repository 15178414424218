<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form
              @submit.prevent="productCategoryUpdate"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  name: "EditProductCategory",
  data() {
    return {
      name: "Edit Product Category",
      form: {
        name: "",
      },
      errors: {},
      isSubmitting: false,
    };
  },
  beforeMount() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "product_category/" + id, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => {
        this.form.name = res.data.data.name;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    onImageSelected(event) {
      this.form.image = event.target.files[0];
    },
    productCategoryUpdate() {
      let id = this.$route.params.id;
      this.isSubmitting = true;
      let formData = new FormData();
      formData.set("name", this.form.name);
      formData.set("code", this.form.code);
      axios
        .post(
          this.$store.state.api + "product_category/update/" + id,
          formData,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$noty.success("Your product category has been updated!");
          this.$router.push({ name: "ProductCategory" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
