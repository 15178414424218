<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Venue</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form
              @submit.prevent="productCategoryInsert"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  name: "FormProductCategory",
  data() {
    return {
      name: "Form Product Category",
      form: {
        name: "",
      },
      errors: {},
      isSubmitting: false,
    };
  },
  methods: {
    onImageSelected(event) {
      this.form.image = event.target.files[0];
    },
    productCategoryInsert() {
      this.isSubmitting = true;
      let formData = new FormData();
      formData.set("name", this.form.name);
      axios
        .post(this.$store.state.api + "product_category/store", formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your product_category has been saved!");
          this.$router.push({ name: "ProductCategory" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
