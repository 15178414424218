import ProductCategory from './ProductCategory.vue'
import ProductCategoryCreate from './Create.vue'
import ProductCategoryEdit from './Edit.vue'

export default [
    {
        path: '/product_category',
        name: 'ProductCategory',
        component: ProductCategory
    },
    {
        path: '/product_category/create',
        name: 'ProductCategoryCreate',
        component: ProductCategoryCreate
    },
    {
        path: '/product_category/edit/:id',
        name: 'ProductCategoryEdit',
        component: ProductCategoryEdit
    },
]
